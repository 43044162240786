.Home {
  display: flex;
  flex-direction: column;
  width: 100%;
  &__intro {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vmin;
    h1 {
      font-family: "Fredericka", 'Times New Roman', Times, serif;
      font-weight: 400;
      font-size: 6rem;
      font-variant: small-caps;
      color: black;
      text-shadow: 3px 6px rgba(0,0,0,0.2);
    }
  }
}