@use "sass:math";

$dinA4H: 29.7cm;
$dinA4W: 21cm;
$divineProportion: 1.618;
$divineInverse: 0.618;
$sidebarBackground: rgb(230, 230, 255);

.Cv__background {
  width: 100%;
  background: linear-gradient(120deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
    linear-gradient(to right, mediumturquoise, powderblue, aqua);
  font-family: "Varela Round", sans-serif;
  color: rgb(50, 50, 50);
}

.Cv__foreground {
  $w: 4.9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12rem 2rem;
  background-image: url("../../assets/mosaics/mosaicPajarita.svg");
  background-repeat: repeat;
  background-size: $w ($w * math.sqrt(3));
  @include printer {
    padding: 0;
  }
}

/* ------------------------------------------------------------ */

.Cv {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 90vw;
  max-width: $dinA4W;
  min-height: $dinA4H;
  box-shadow: 3px 3px 2px 3px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  @include printer {
    width: $dinA4W;
    height: $dinA4H;
    margin: 0;
  }
}

.Cv__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include landscape {
    flex-direction: row;
    align-items: stretch;
  }
  @include printer {
    flex-direction: row;
    align-items: stretch;
  }
}

.Cv__myPhotoWrapper {
  $x: $dinA4W - $divineInverse * $dinA4W;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: $x;
  height: $x;
  background-color: white;
  @include desktop {
    background-color: $sidebarBackground;
  }
  @include printer {
    background-color: $sidebarBackground;
  }
}

.Cv__myPhoto {
  border: 2px solid rgb(50, 50, 50);
  border-radius: 1000px;
  --x: 23rem;
  height: var(--x);
  width: var(--x);
  filter: grayscale(80%);
  &:hover {
    filter: grayscale(20%);
  }
}

.Cv__personal {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $divineInverse * $dinA4W;
  padding: 3rem;
}

.Cv__personalDescription {
  flex-grow: 1;
  h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  p {
    font-size: 1.2rem;
    margin-bottom: 0.3rem;
  }
}

.Cv__personalInfoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Cv__personalInfo {
  font-size: 1.3rem;
  margin-top: 0.8rem;
  display: flex;
  align-items: center;
  img {
    --x: 2rem;
    width: var(--x);
    height: var(--x);
    margin-right: 1.5rem;
  }
}

/* ------------------------------------------------------------ */

.Cv__sectionHeader {
  text-transform: uppercase;
  color: tomato;
  font-size: 1.5rem;
  border-bottom: 1px solid rgb(180, 180, 180);
}

/* ------------------------------------------------------------ */

.Cv__body {
  flex-grow: 1;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  @include tablet {
    flex-direction: row;
    align-items: stretch;
  }
  @include printer {
    flex-direction: row;
    align-items: stretch;
  }
}

.Cv__sidebar {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $dinA4W - $divineInverse * $dinA4W;
  padding: 3rem;
  background-color: white;
  @include desktop {
    background-color: $sidebarBackground;
  }
  @include printer {
    background-color: $sidebarBackground;
  }
}

.Cv__social,
.Cv__langs,
.Cv__skills {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
}

.Cv__skills {
  margin-bottom: 0;
}

.Cv__socialList {
  display: flex;
  justify-content: space-around;
  padding-top: 1.8rem;
  list-style-type: none;
  li {
    display: flex;
    align-items: center;
  }
  img {
    --x: 3rem;
    height: var(--x);
    width: var(--x);
    margin-right: 1.5rem;
  }
}

.Cv__langList {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  text-align: center;
  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 1.8rem;
  }
  img {
    --x: 2.4rem;
    height: var(--x);
    width: var(--x);
    margin-bottom: 1rem;
    border-radius: 200px;
    box-shadow: 0 0 0 1px gray;
    object-fit: cover;
  }
  span {
    display: none;
  }
  p {
    margin: 0.3rem 0 0 0;
    font-size: 1rem;
  }
}

.Cv__skillList {
  list-style-type: none;
  li {
    display: flex;
    align-items: center;
    margin: 1.5rem 0;
    font-size: 1.2rem;
  }
  img {
    --x: 1.8rem;
    height: var(--x);
    width: var(--x);
    margin-right: 1rem;
    object-fit: scale-down;
  }
  span {
    flex-grow: 1;
  }
  em {
    font-style: normal;
    cursor: default;
    transform: translateY(-2px);
  }
}

.Cv__lowSkill {
  color: #cd7f32;
}

.Cv__mediumSkill {
  color: darkgray;
}

.Cv__highSkill {
  color: gold;
}

/* ------------------------------------------------------------ */

.Cv__main {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $divineInverse * $dinA4W;
  padding: 3rem;
}

.Cv__studies,
.Cv__work {
  display: flex;
  flex-direction: column;
  margin-bottom: 6rem;
}

.Cv__work {
  margin-bottom: 0;
}

.Cv__study {
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
  img {
    align-self: center;
    --x: 5rem;
    height: var(--x);
    width: var(--x);
    margin-bottom: 2rem;
    filter: grayscale(80%);
  }
  @include landscape {
    flex-direction: row;
    img {
      margin-bottom: 0;
      margin-right: 2rem;
    }
  }
  @include printer {
    flex-direction: row;
    img {
      margin-bottom: 0;
      margin-right: 2rem;
    }
  }
  &:last-child {
    display: none;
  }
}

.Cv__studyInfo {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  div {
    display: flex;
    align-items: center;
    width: 100%;
  }
  h4 {
    flex-grow: 1;
    font-size: 1.5rem;
  }
  span {
    margin-left: 1rem;
    font-size: 1rem;
    text-align: end;
  }
  p {
    margin-top: 0.6rem;
    font-size: 1.2rem;
  }
}

.Cv__workInfo {
  margin-top: 4rem;
  div {
    display: flex;
  }
  h4 {
    flex-grow: 1;
    margin: 0;
  }
  span {
    font-size: 1.1rem;
    text-align: end;
  }
  em {
    font-size: 1.2rem;
    font-style: normal;
  }
  p {
    margin: 1.2rem 0 0 0;
    font-size: 1.4rem;
    color: rgb(120, 120, 120);
  }
}

/* ------------------------------------------------------------ */
