.MosaicCode {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-radius: 12px;
  border: 1px solid black;
  background-color: white;
  box-shadow: 5px 5px 7px 2px rgba(0,0,0,0.3);
  overflow-x: auto;
  h3 {
    margin-bottom: 2rem;
    border-bottom: 1px solid silver;
    text-transform: uppercase;
    color: black;
  }
  &__section {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 2rem;
  }
  &__sectionHeader {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    span {
      font-weight: 600;
    }
    svg {
      $x: 4rem;
      width: $x;
      height: $x;
      margin-right: 0.5rem;
      path {
        stroke: none;
      }
    }
  }
  &__code {
    padding: 0.5rem;
    background-color: rgb(240,240,240);
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }
}