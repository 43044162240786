.Projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: beige;
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 10rem $padH;
    background-image: url("../../assets/mosaics/octagonsMosaic2.svg");
    background-repeat: repeat;
    background-position: center;
    background-size: 8rem;
  }
  &__header {
    padding: 2rem 5rem;
    margin-bottom: 7rem;
    border: 1px solid silver;
    text-transform: uppercase;
    text-align: center;
    text-shadow: 1px 0 black, 0 1px black, -1px 0 black, 0 -1px black;
    font-size: 3rem;
    color: white;
    letter-spacing: 2px;
    background-color: white;
    box-shadow: 3px 3px 3px 0 rgba(0,0,0,0.3);
  }
}