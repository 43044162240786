.Project {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 90rem;
  padding: 1rem;
  margin-bottom: 6rem;
  border: 1px solid silver;
  border-radius: 29px;
  background-color: white;
  box-shadow: 10px 10px 15px 0 rgba(0,0,0,0.3);
  @include tablet {
    flex-direction: row;
  }
  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    svg, img {
      width: 30rem;
      @include tablet {
        width: 36rem;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    padding: 1rem 3rem;
    h3 {
      margin-bottom: 1rem;
      font-variant: small-caps;
      text-decoration: underline;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    li {
      display: flex;
      margin-bottom: 0.6rem;
    }
    span {
      min-width: 10rem;
      font-size: 1.2rem;
      color: gray;
      &::after {
        content: ":";
      }
    }
    a {
      color: blue;
      text-decoration: underline;
    }
  }
  &__synopsis {
    padding: 0.5rem 1rem;
    text-align: justify;
    text-indent: 3rem;
    p {
      margin-bottom: 0.5rem;
    }
  }
}