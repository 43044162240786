@use "sass:math";

.MosaicGallery {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  &__mosaicWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    &--triReligious {
      $gW: 0.5 * math.sqrt(3) * 6rem;
      $color1: rgba(blue, 0.9);
      $color2: rgba(darkorange, 0.9);
      $color3: rgba(indigo, 0.9);
      $color4: rgba(0, 163, 0, 0.9);
      background-image: repeating-linear-gradient(
        120deg,
        $color4 0.9 * $gW,
        $color1 1.1 * $gW,
        $color1 1.9 * $gW,
        $color2 2.1 * $gW,
        $color2 2.9 * $gW,
        $color3 3.1 * $gW,
        $color3 3.9 * $gW,
        $color4 4.1 * $gW,
        $color4 4.9 * $gW
      );
    }
    &--beehive {
      background: linear-gradient(
        60deg,
        rgba(255, 255, 0, 0.5),
        rgba(255, 215, 0, 0.6),
        rgba(255, 165, 0, 0.7),
        rgba(255, 140, 0, 0.8),
        rgba(178, 34, 34, 0.7)
      ),
      linear-gradient(
        -60deg,
        rgba(255, 140, 000, 0.8),
        rgba(255, 215, 000, 0.7),
        rgba(255, 255, 100, 0.6),
        rgba(255, 140, 000, 0.5)
      );
    }
    &--hexCircles {
      background-color: #FFFF33;
    }
  }
  &__mosaic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 90vh;
    padding: 30rem 1rem;
    background-repeat: repeat;
    background-position: center;
    &--nazari {
      $x: 4.9rem;
      background-image: url("../../../assets/mosaics/nazariMosaic.svg");
      background-size: $x ($x * math.sqrt(3));
    }
    &--triReligious {
      $x: 6rem;
      background-image: url("../../../assets/mosaics/triReligiousMosaic.svg");
      background-size: $x ($x * math.sqrt(3));
      background-position: (-0.5 * $x) 0;
    }
    &--hexagons {
      $x: 6rem;
      background-image: url("../../../assets/mosaics/hexagonsMosaic.svg");
      background-size: $x ($x * math.sqrt(3));
    }
    &--beehive {
      $x: 6rem;
      background-image: url("../../../assets/mosaics/beehiveMosaic.svg");
      background-size: $x ($x * math.sqrt(3));
    }
    &--lemons {
      $x: 7rem;
      background-image: url("../../../assets/mosaics/lemonsMosaic.svg");
      background-size: (0.85 * $x) $x;
    }
    &--arabic {
      $x: 10rem;
      background-image: url("../../../assets/mosaics/arabicMosaic.svg");
      background-size: $x;
    }
    &--clouds {
      $x: 5rem;
      background-image: url("../../../assets/mosaics/cloudsMosaic.svg");
      background-size: $x;
    }
    &--hex {
      $x: 12rem;
      background-image: url("../../../assets/mosaics/hexMosaic.svg");
      background-size: ($x * math.sqrt(3)) $x;
    }
    &--arrows {
      $x: 6rem;
      background-image: url("../../../assets/mosaics/arrowsMosaic.svg");
      background-size: $x ($x * math.sqrt(3));
    }
    &--wires {
      $x: 30rem;
      background-image: url("../../../assets/mosaics/wiresMosaic.svg");
      background-size: $x;
    }
    &--curves {
      $x: 9rem;
      background-image: url("../../../assets/mosaics/curvesMosaic.svg");
      background-size: $x;
    }
    &--airplane {
      $x: 8rem;
      background-image: url("../../../assets/mosaics/airplaneMosaic.svg");
      background-size: $x;
    }
    &--octagons {
      $x: 4rem;
      background-image: url("../../../assets/mosaics/octagonsMosaic1.svg");
      background-size: $x;
    }
    &--pentagons {
      background-image: url("../../../assets/mosaics/pentagonsMosaic.svg");
      background-size: 29rem auto;
    }
    &--hexCircles {
      $x: 10rem;
      background-image: url("../../../assets/mosaics/hexCirclesMosaic.svg");
      background-size: $x ($x * math.sqrt(3));
    }
  }
}