// Fonts

// Diablo

@font-face {
  font-family: Diablo;
  src: url(../../assets/fonts/DiabloLight.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Diablo;
  src: url(../../assets/fonts/DiabloHeavy.ttf);
  font-weight: 700;
}

// Cloister

@font-face {
  font-family: Cloister;
  src: url(../../assets/fonts/CloisterBlack.ttf);
  font-style: normal;
  font-weight: 700;
}

// Fredericka the Great

@font-face {
  font-family: Fredericka;
  src: url(../../assets/fonts/FrederickatheGreat-Regular.ttf);
  font-weight: 400;
}

// Monoton

@font-face {
  font-family: Monoton;
  src: url(../../assets/fonts/Monoton-Regular.ttf);
  font-weight: 400;
}

// NotoSerifTC

@font-face {
  font-family: ChineseSerif;
  src: url(../../assets/fonts/NotoSerifTC-Medium.otf);
  font-style: normal;
  font-weight: 500;
}

// Shojumaru

@font-face {
  font-family: Shojumaru;
  src: url(../../assets/fonts/Shojumaru-Regular.ttf);
  font-style: normal;
  font-weight: 400;
}

// Varela Round

@font-face {
  font-family: Varela Round;
  src: url(../../assets/fonts/VarelaRound-Regular.ttf);
}

// Montserrat

@font-face {
  font-family: Montserrat;
  src: url(../../assets/fonts/Montserrat-Thin.ttf);
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: Montserrat;
  src: url(../../assets/fonts/Montserrat-ThinItalic.ttf);
  font-style: italic;
  font-weight: 100;
}

@font-face {
  font-family: Montserrat;
  src: url(../../assets/fonts/Montserrat-ExtraLight.ttf);
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: Montserrat;
  src: url(../../assets/fonts/Montserrat-ExtraLightItalic.ttf);
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: Montserrat;
  src: url(../../assets/fonts/Montserrat-Light.ttf);
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: Montserrat;
  src: url(../../assets/fonts/Montserrat-LightItalic.ttf);
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: Montserrat;
  src: url(../../assets/fonts/Montserrat-Regular.ttf);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Montserrat;
  src: url(../../assets/fonts/Montserrat-Italic.ttf);
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: Montserrat;
  src: url(../../assets/fonts/Montserrat-Medium.ttf);
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: Montserrat;
  src: url(../../assets/fonts/Montserrat-MediumItalic.ttf);
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: Montserrat;
  src: url(../../assets/fonts/Montserrat-SemiBold.ttf);
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: Montserrat;
  src: url(../../assets/fonts/Montserrat-SemiBoldItalic.ttf);
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: Montserrat;
  src: url(../../assets/fonts/Montserrat-Bold.ttf);
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: Montserrat;
  src: url(../../assets/fonts/Montserrat-BoldItalic.ttf);
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: Montserrat;
  src: url(../../assets/fonts/Montserrat-ExtraBold.ttf);
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: Montserrat;
  src: url(../../assets/fonts/Montserrat-ExtraBoldItalic.ttf);
  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: Montserrat;
  src: url(../../assets/fonts/Montserrat-Black.ttf);
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: Montserrat;
  src: url(../../assets/fonts/Montserrat-BlackItalic.ttf);
  font-style: italic;
  font-weight: 900;
}
