// Mixins

@mixin customScrollbar {
  $scrollbarColor: rgb(120, 150, 220);
  $scrollbarBackground: rgb(240, 240, 240);
  scrollbar-width: thin;
  scrollbar-height: thin;
  scrollbar-color: $scrollbarColor $scrollbarBackground;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: $scrollbarBackground;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $scrollbarColor;
  }
  &::-webkit-scrollbar-button {
    height: 10px;
    background-color: $scrollbarColor;
  }
  &::-webkit-scrollbar-button:single-button:vertical:decrement {
    border-radius: 50px 50px 0 0;
  }
  &::-webkit-scrollbar-button:single-button:vertical:increment {
    border-radius: 0 0 50px 50px;
  }
}

@mixin textShadow($color) {
  text-shadow: 2px 0 $color, -2px 0 $color, 0 2px $color, 0 -2px $color;
}
