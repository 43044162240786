.SvgIcons {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__intro {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10rem 1rem;
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 6rem;
    h1 {
      margin-bottom: 3rem;
      text-align: center;
      font-family: "Fredericka", serif;
      font-size: 6rem;
      font-weight: normal;
      font-variant: small-caps;
    }
    img {
      max-width: 100%;
    }
  }
  &__info {
    max-width: 50rem;
    margin-bottom: 6rem;
    font-size: 1.8rem;
    p {
      margin-bottom: 2rem;
      text-align: justify;
    }
    a {
      color: blue;
      text-decoration: underline;
    }
  }
  &__source {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10rem;
    &Header {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
      span {
        font-size: 2rem;
        font-weight: 600;
        text-decoration: underline;
      }
      svg {
        $x: 6rem;
        width:  $x;
        height: $x;
        margin-right: 1rem;
        path {
          stroke: none;
        }
      }
    }
    code {
      padding: 1rem;
      margin-bottom: 6rem;
      background-color: rgb(240,240,240);
      overflow-wrap: break-word;
      white-space: pre-wrap;
    }
  }
}