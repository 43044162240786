// Global

body {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  font-family: "Montserrat", Times, serif;
  font-size: 1.5rem;
  color: $gray1;
  @include customScrollbar;
}

button {
  font-family: "Montserrat", 'Times New Roman', Times, serif;
  color: $gray1;
}

#root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.App {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-top: $headerH;
  @include printer {
    padding: 0;
  }
}