.Nav {
  $color: rgb(50,50,50);
  display: flex;
  align-items: center;
  height: 100%;
  &__link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 1.618 * $headerH;
    &:hover {
      box-shadow: 0 -1px 0 0 $color inset;
    }
    svg {
      $x: 3.6rem;
      height: $x;
      width: $x;
      stroke: $color;
      fill: $color;
      path {
        fill: none;
      }
    }
    strong {
      margin-top: 0.5rem;
      text-transform: uppercase;
      font-size: 1rem;
      color: $color;
    }
  }
}