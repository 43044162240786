.Logo {
  display: flex;
  align-items: center;
  height: 100%;
  svg {
    height: $headerH;
    width:  $headerH;
    margin-right: 1rem;
  }
  &__text {
    font-family: "Monoton", serif;
    font-size: 3.3rem;
    font-weight: 400;
    color: black;
  }
}